import React, { Component } from 'react';
import Helmet from 'react-helmet';
import FontFaceObserver from 'fontfaceobserver';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
// Import Google Fonts
import 'typeface-roboto';
import 'typeface-lato';
// Global styles
import '@material/icon-button/dist/mdc.icon-button.css';
import '../theme/index.less';
import Header from '../components/Header';
import Footer from '../components/Footer';

export const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

class Layout extends Component {
  // state = {
  //   loaded: false,
  // };
  // componentDidMount() {
  //   // Avoid FOUT, dont show content until fonts load
  //   const headers = new FontFaceObserver('Lato');
  //   const body = new FontFaceObserver('Roboto');
  //   Promise.all([headers.load(), body.load()])
  //     .then(() => {
  //       console.log('[FontFaceObserver] Fonts loaded');
  //       this.setState({ loaded: true });
  //     })
  //     .catch(() => {
  //       console.warn('[FontFaceObserver] Fonts timed out');
  //       this.setState({ loaded: true });
  //     });
  // }
  render() {
    // const { loaded } = this.state;
    const { children, page, home } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <StaticQuery query={query}>
          {({ site }) => (
            <div>
              <Helmet
                defaultTitle={site.siteMetadata.title}
                titleTemplate={`%s | ${site.siteMetadata.title}`}>
                {page && <title>{page.frontmatter.metaTitle}</title>}
                {page && (
                  <meta
                    name="description"
                    content={page.frontmatter.metaDescription}
                  />
                )}
              </Helmet>
              <Header home={home} />
              <main>{children}</main>
              <Footer data={{ site }} />
            </div>
          )}
        </StaticQuery>
      </ThemeProvider>
    );
  }
}
export default Layout;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        copyright
        description
        phone
        email
        linkedInUrl
      }
    }
  }
`;
