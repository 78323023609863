import React, { Component } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { MdMenu } from 'react-icons/md';
import { IconButton } from '@rmwc/icon-button';
import logoLight from '../images/logo-white-outline.png';

const Nav = styled('nav')`
  &.bar {
    padding: 0.5em 0;
  }
  &.pos-fixed.bar .logo {
    position: absolute;
    height: 72px;
    width: auto;
    max-height: none;
    top: -20px;
  }
  &.bar .logo {
    top: 0;
    /* width: 100%; */
    max-height: 96px;
    z-index: 100;
  }
  .bar__module {
    margin-bottom: 0;
  }
  a {
    display: inline-block;
  }
  &.pos-fixed {
    top: 0;
    position: fixed;
    z-index: 98;
    width: 100%;
    padding: 1.6em 0;
    animation: fadeInDown 0.3s ease-out forwards;
    box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.2);
    ${breakpoint('xs', 'lg')`
      padding: 1em 0;
      top: 56px;
    `};
  }
  &&&.home-absolute {
    top: 0;
    position: absolute;
    z-index: 98;
    width: 100%;
    box-shadow: none;
    ${breakpoint('xs', 'lg')`
      top: 56px;
    `};
    ${breakpoint('lg')`
      /* background: none; */
    `};
    .menu-horizontal a:not(:hover) {
      opacity: 0.8;
    }
    .logo {
      z-index: 100;
    }
  }
  ${breakpoint('xs', 'lg')`
    &&& li {
      display: list-item;
      font-size: 14px;
      padding: 0.5em 0;
      text-align: center;
      :not(:first-child) {
        margin-left: 0;
      }
    \}
  `};
`;

const MobileNav = styled('nav')`
  &&& {
    height: 56px;
    margin-top: 0;
    padding: 0;
  }
  a {
    display: inline-block;
    height: 56px;
  }
  &.bar .logo {
    position: relative;
    max-height: 48px;
  }
  &.pos-fixed {
    top: 0;
    position: fixed;
    z-index: 99;
    width: 100%;
    animation: fadeInDown 0.3s ease-out forwards;
    box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.1);
    ${breakpoint('xs', 'lg')`
      box-shadow: none;
    `};
  },
`;

const NavButtons = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  a {
    width: 100%;
    display: inline-block;
  }
  ${breakpoint('xs', 'lg')`
    .bar__module {
      flex: 1 0 100%;
      &:last-child {
        margin-bottom: 1.6em !important;
      }
    \}
  `};
`;
const MenuButton = styled(IconButton)`
  &&& {
    margin-top: 4px;
    color: #adb8c5;
  }
`;

class Header extends Component {
  state = {
    fixed: false,
    menuOpen: false,
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll = e => {
    const { fixed } = this.state;
    const offset = 112;
    if (!fixed && window.pageYOffset >= offset) {
      this.setState({ fixed: true });
    } else if (fixed && window.pageYOffset < 1) {
      this.setState({ fixed: false });
    }
  };
  closeMenu = () => {
    // Add slight delay to show touch/click effect before closing
    this.setState({ menuOpen: false });
  };
  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };
  render() {
    const { fixed, menuOpen } = this.state;
    const { home } = this.props;
    return (
      <header className="nav-container" style={{ paddingTop: fixed ? 120 : 0 }}>
        <MobileNav
          className={classNames('bar bar--sm visible-xs visible-sm bg--dark', {
            'pos-fixed': fixed,
          })}>
          <div className="container">
            <div className="row">
              <div className="col-6 col-sm-2">
                <Link to="/" onClick={this.closeMenu}>
                  <img className="logo logo-dark" alt="logo" src={logoLight} />
                  <img className="logo logo-light" alt="logo" src={logoLight} />
                </Link>
              </div>
              <div className="col-6 col-sm-10 text-right">
                <MenuButton onClick={this.toggleMenu} icon={<MdMenu />} />
              </div>
            </div>
          </div>
        </MobileNav>
        <Nav
          className={classNames('bar bar-1 bg--dark', {
            'hidden-xs hidden-sm': !menuOpen,
            'pos-fixed': fixed,
            'home-absolute': home && !fixed,
          })}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 hidden-xs hidden-sm">
                <div className="bar__module">
                  <Link to="/">
                    <img
                      className="logo logo-dark"
                      alt="logo"
                      src={logoLight}
                    />
                    <img
                      className="logo logo-light"
                      alt="logo"
                      src={logoLight}
                    />
                  </Link>
                </div>
              </div>
              <NavButtons
                className="col-lg-8 text-right text-left-xs text-left-sm"
                onClick={this.closeMenu}>
                <div className="bar__module">
                  <ul className="menu-horizontal text-left-lg">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/projects">Projects</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                  </ul>
                </div>
                <div className="bar__module">
                  <Link
                    className="btn btn--sm btn--primary type--uppercase"
                    to="/contact">
                    <span className="btn__text">Contact Us</span>
                  </Link>
                </div>
              </NavButtons>
            </div>
          </div>
        </Nav>
      </header>
    );
  }
}

export default Header;
