import React, { Component } from 'react';
import { isElement } from 'react-is';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Typography = styled('div')`
  ${breakpoint('xs', 'lg')`
    h1 {
      font-size: 1.5em;
    \}
  `}
  img {
    max-width: 100%;
  }
  ul {
    list-style-type: disc;
    margin-left: 1.6em;
  },
`;

class Content extends Component {
  render() {
    const { content, ...passThroughProps } = this.props;

    // Set prop defaults
    passThroughProps.component = passThroughProps.component || 'div';

    if (isElement(content)) {
      return <Typography {...passThroughProps}>{content}</Typography>;
    }
    return (
      <Typography
        {...passThroughProps}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
}

export default Content;
