import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import LinkedIn from 'mdi-material-ui/Linkedin';
import logoLight from '../images/logo-white-outline.png';

const FooterLogo = styled('img')`
  && {
    height: 72px;
    max-height: none;
  }
`;

class Footer extends Component {
  render() {
    const {
      data: { site },
    } = this.props;
    return (
      <footer className="footer-3 text-center-xs space--xxs bg--dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <Link to="/">
                <FooterLogo alt="logo" className="logo" src={logoLight} />
              </Link>
            </div>
            <div className="col-sm-6 text-right text-center-xs">
              <ul className="social-list list-inline list--hover">
                <li>
                  <a href={site.siteMetadata.linkedInUrl} target="__blank">
                    <LinkedIn />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col text-left text-center-xs">
              <Link to="/contact" className="type--fine-print">
                {site.siteMetadata.description}
              </Link>
            </div>
            <div className="col text-right text-center-xs">
              <span
                className="type--fine-print"
                role="img"
                aria-label="copyright">
                &copy; {new Date().getFullYear()} {site.siteMetadata.copyright}
              </span>
              <Link className="type--fine-print" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="type--fine-print" to="/terms-of-service">
                Legal
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
